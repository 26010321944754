import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BanType, UserBanHistory } from '../../../../application/types/UserBans';
import './UserBanHistoryTab.css';

interface UserBanHistoryTabProps {
  banHistory: UserBanHistory[];
}

const UserBanHistoryTab: FC<UserBanHistoryTabProps> = ({ banHistory }) => {
  const { t } = useTranslation();

  const actionTypeCopy = (userBanHistory: UserBanHistory): string => {
    switch (userBanHistory.actionType) {
      case BanType.TEMPORARY_BAN:
        return t('USER.DETAILS.BANHISTORY.TABLEBODY.ACTIONTYPE.TEMPORARYBAN', { banDuration: userBanHistory.duration });
      case BanType.PERMANENT_BAN:
        return t('USER.DETAILS.BANHISTORY.TABLEBODY.ACTIONTYPE.PERMANENTBAN');
      case BanType.UNBAN:
        return t('USER.DETAILS.BANHISTORY.TABLEBODY.ACTIONTYPE.UNBAN');
      case BanType.REVIEW_EXPIRED_BAN:
        return t('USER.DETAILS.BANHISTORY.TABLEBODY.ACTIONTYPE.REVIEWEXPIREDBAN');
      default:
        return '';
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.CONVERSATIONID')}</th>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.CREATED')}</th>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.BANNEDBY')}</th>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.ACTIONTYPE')}</th>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.SOURCE')}</th>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.REASON')}</th>
          <th>{t('USER.DETAILS.BANHISTORY.TABLEHEADER.EXPIRY')}</th>
        </tr>
      </thead>
      <tbody>
        {banHistory.map((row, index) => (
          <tr key={index}>
            <td className='userBanHistoryTab-tableCell'>{row.providerChannelId ?? ''}</td>
            <td className='userBanHistoryTab-tableCell'>{row.createdAt.toLocaleDateString()}</td>
            <td className='userBanHistoryTab-tableCell'>{row.moderator}</td>
            <td className='userBanHistoryTab-tableCell'>{actionTypeCopy(row)}</td>
            <td className='userBanHistoryTab-tableCell'>
              {t(`USER.DETAILS.BANHISTORY.TABLEBODY.ACTIONSOURCE.${row.source}`)}
            </td>
            <td className='userBanHistoryTab-tableCell'>{row.reason}</td>
            <td className='userBanHistoryTab-tableCell'>{row.expiresAt ? row.expiresAt.toLocaleDateString() : ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserBanHistoryTab;
