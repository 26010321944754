import { Box } from '@mui/material';
import { FC } from 'react';
import ModeratorQueue from '../components/Layout/Admin/ModeratorQueue';
import { ModeratorQueueProvider } from '../../application/context/ModeratorQueueContext';

const AdminPage: FC = () => {
  return (
    <Box className='AdminPage' sx={{ height: '100%' }}>
      <ModeratorQueueProvider>
        <ModeratorQueue />
      </ModeratorQueueProvider>
    </Box>
  );
};

export default AdminPage;
