import ChannelRepository from '../../infrastructure/repositories/ChannelRepository';
import { Channel } from '../types/Channel';
import { ChannelDto } from '../types/dto/ChannelDto';

class ChannelService {
  public getChannelQuery(id?: string): Promise<ChannelDto> {
    return ChannelRepository.getChannelById(id);
  }

  public formatChannelFromDto(data: ChannelDto): Channel {
    const {
      channelId,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages,
      status,
      readOnly,
    } = data;

    return {
      channelId,
      status,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages: messages.map((message) => ({ ...message, isSelected: false })),
      readOnly,
    };
  }

  public async getChannel(id?: string): Promise<Channel> {
    const {
      channelId,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages,
      status,
      readOnly,
    } = await ChannelRepository.getChannelById(id);

    return {
      channelId,
      status,
      providerId,
      createdById,
      createdAt,
      productId,
      productPath,
      productPicture,
      members,
      messages: messages.map((message) => ({ ...message, isSelected: false })),
      readOnly,
    };
  }
}

export const channelService = new ChannelService();
