import { FC } from 'react';
import SideBarFull from './SideBarFull';
import { Avatar, Box, Drawer, IconButton, List, ListItem, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { theme } from '../../../../theme';
import { AdminPanelSettingsOutlined, ChatBubbleOutline } from '@mui/icons-material';
import { matchPath, useNavigate } from 'react-router';
import { ApplicationRoutes } from '../../../../application/constants/navigation';
import './SideBar.css';
import { SMALL_SIDEBAR_WIDTH, useSideBarContext } from '../../../../application/context/SideBarContext';
import { useAuth } from '../../../../application/context/AuthContext';
import { isDefined } from '../../../../utils/type-utils';
import { useModeratorLanguage } from '../../../../application/context/ModeratorContext';
import { useTranslation } from 'react-i18next';

const SideBar: FC = () => {
  const { handleFullSideBar, handleSubMenu } = useSideBarContext();
  const navigate = useNavigate();
  const { flagToDisplay } = useModeratorLanguage();
  const { auth } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <SideBarFull />
      <Drawer
        sx={{
          width: SMALL_SIDEBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SMALL_SIDEBAR_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'
        open={true}
      >
        <List>
          <ListItem sx={{ padding: theme.spacing(1.5) }} alignItems='center'>
            <IconButton onClick={() => handleFullSideBar()} sx={{ borderRadius: 0 }}>
              <MenuIcon sx={{ color: theme.palette.grey[900] }} fontSize='medium' />
            </IconButton>
          </ListItem>
        </List>
        <List>
          {isDefined(auth) && auth.isAuthenticated ? (
            <ListItem sx={{ padding: theme.spacing(1.5) }} alignItems='center'>
              <Tooltip title={t('SIDEBAR.LANGUAGESELECTION.ICON')}>
                <Box onClick={() => handleSubMenu()} sx={{ paddingLeft: '4px', cursor: 'pointer' }}>
                  <Avatar src='' sx={{ width: 31, height: 31 }} />
                  <Avatar className='sideBar-floatingFlag' sx={{ width: 12, height: 12 }} src={flagToDisplay()} />
                </Box>
              </Tooltip>
            </ListItem>
          ) : (
            ''
          )}
          <ListItem sx={{ padding: theme.spacing(1.5) }} alignItems='center'>
            <Tooltip title={t('SIDEBAR.SEARCHBAR.PLACEHOLDER')}>
              <IconButton
                onClick={() => {
                  handleFullSideBar();
                }}
                sx={{ borderRadius: 0 }}
              >
                <SearchIcon sx={{ color: theme.palette.grey[900] }} fontSize='medium' />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ padding: theme.spacing(1.5) }} alignItems='center'>
            <Tooltip title={t('MENU.CONVERSATIONS')}>
              <IconButton
                className={
                  matchPath(location.pathname, ApplicationRoutes.CONVERSATIONS_ALL) ? 'button-link__active' : ''
                }
                onClick={() => navigate(ApplicationRoutes.CONVERSATIONS_ALL)}
                sx={{ borderRadius: 0 }}
              >
                <ChatBubbleOutline sx={{ color: theme.palette.grey[900] }} fontSize='medium' />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem sx={{ padding: theme.spacing(1.5) }} alignItems='center'>
            <Tooltip title={t('MENU.MODERATORQUEUE')}>
              <IconButton
                className={matchPath(location.pathname, ApplicationRoutes.ADMIN) ? 'button-link__active' : ''}
                onClick={() => navigate(ApplicationRoutes.ADMIN)}
                sx={{ borderRadius: 0 }}
              >
                <AdminPanelSettingsOutlined sx={{ color: theme.palette.grey[900] }} fontSize='medium' />
              </IconButton>
            </Tooltip>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default SideBar;
