import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import ConversationsMember from './ConversationsMember';
import ConversationsItem from './ConversationsItem';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import { ContentCopy } from '@mui/icons-material';
import { useChannel } from '../../../../application/context/ChannelContext';
import { Member, MemberRole } from '../../../../application/types/Member';

const ConversationsSideBar: FC = () => {
  const { t } = useTranslation();
  const { providerId, members, sellerBanCount, buyerBanCount, onUserDetailsModalOpenClose } = useChannel();

  const copyConversationId = (): void => {
    navigator.clipboard.writeText(providerId || '');
  };

  const getMember = (role: MemberRole): Member | undefined => members.find((member) => member.role === role);

  return (
    <Box sx={{ height: '100%', minWidth: '320px' }}>
      <Box padding={`${theme.spacing(2)} ${theme.spacing(4)}`}>
        <Typography variant='body2' marginBottom={theme.spacing(1)}>
          {t('CONVERSATIONS.SIDEBAR.CONVID')}
        </Typography>
        <Typography variant='h2'>
          {providerId}
          <Button
            variant='outlined'
            color='info'
            size='small'
            onClick={() => copyConversationId()}
            sx={{ float: 'right', minWidth: 48, minHeight: 32, borderRadius: '14px' }}
          >
            <ContentCopy fontSize='small' />
          </Button>
        </Typography>
      </Box>
      <Box padding={`${theme.spacing(2)} ${theme.spacing(4)}`}>
        <Typography variant='body2' marginBottom={theme.spacing(1)}>
          {t('CONVERSATIONS.SIDEBAR.MEMBERS')}
        </Typography>
        {getMember(MemberRole.BUYER) && (
          <ConversationsMember
            color={theme.palette.notificationColor.main}
            title={t('CONVERSATIONS.SIDEBAR.BUYER')}
            member={getMember(MemberRole.BUYER)!}
            banCount={buyerBanCount}
            onUserDetailsModalOpenClose={onUserDetailsModalOpenClose}
          />
        )}
        {getMember(MemberRole.SELLER) && (
          <ConversationsMember
            color={theme.palette.notificationColor.light}
            title={t('CONVERSATIONS.SIDEBAR.SELLER')}
            member={getMember(MemberRole.SELLER)!}
            banCount={sellerBanCount}
            onUserDetailsModalOpenClose={onUserDetailsModalOpenClose}
          />
        )}
      </Box>
      <Box padding={`${theme.spacing(2)} ${theme.spacing(4)}`}>
        <Typography variant='body2' marginBottom={theme.spacing(1)}>
          {t('CONVERSATIONS.SIDEBAR.ITEM')}
        </Typography>
        <ConversationsItem />
      </Box>
    </Box>
  );
};

export default ConversationsSideBar;
