import { createContext, useContext, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';

export type SideBarContextProps = {
  isFullSideBarOpen: boolean;
  isSubMenuOpen: boolean;
  handleFullSideBar: Function;
  handleSubMenu: Function;
};

export const SMALL_SIDEBAR_WIDTH = 64;
export const FULL_SIDEBAR_WIDTH = 320;

const SideBarContext = createContext<SideBarContextProps>({} as never);

export const SideBarProvider: FCWithChildren = ({ children }): React.JSX.Element => {
  const [isFullSideBarOpen, setIsFullSideBarOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleFullSideBar = (): void => {
    setIsFullSideBarOpen(!isFullSideBarOpen);
  };

  const handleSubMenu = (): void => {
    setIsFullSideBarOpen(true);
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <SideBarContext.Provider value={{ isFullSideBarOpen, isSubMenuOpen, handleFullSideBar, handleSubMenu }}>
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBarContext = (): SideBarContextProps => useContext(SideBarContext);
