import { createContext, useContext, useEffect, useState } from 'react';
import { socketClient } from '../../infrastructure/websocket/socket';
import { FCWithChildren } from '../../infrastructure/types/global';
import { isDefined } from '../../utils/type-utils';
import { ChannelDetailsEvent, SOCKET_CHANNEL_DETAILS_EVENT } from '../types/ChannelDetailsEvent';
import { UserBanEvent, SOCKET_BANNING_DETAILS_EVENT } from '../types/UserBanEvent';
import { MessageDetailsEvent, SOCKET_MESSAGE_DETAILS_EVENT } from '../types/MessageDetailsEvent';
import { SOCKET_UNBANNING_DETAILS_EVENT, UserUnbanEvent } from '../types/UserUnBanEvent';

interface SocketContextProps {
  lastBanningEvent: UserBanEvent | null;
  lastUnbanningEvent: UserUnbanEvent | null;
  lastChannelEvent: ChannelDetailsEvent | null;
  lastMessageEvent: MessageDetailsEvent | null;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

export const SocketProvider: FCWithChildren = ({ children }) => {
  const socket = socketClient;
  const [lastBanningEvent, setLastBanningEvent] = useState<UserBanEvent | null>(null);
  const [lastChannelEvent, setLastChannelEvent] = useState<ChannelDetailsEvent | null>(null);
  const [lastMessageEvent, setLastMessageEvent] = useState<MessageDetailsEvent | null>(null);
  const [lastUnbanningEvent, setLastUnbanningEvent] = useState<UserUnbanEvent | null>(null);

  useEffect(() => {
    if (!isDefined(socket)) {
      throw new Error('Socket is not defined');
    }

    const handleBanningEvent = (event: UserBanEvent): void => {
      setLastBanningEvent(event);
    };

    const handleChannelEvent = (event: ChannelDetailsEvent): void => {
      setLastChannelEvent(event);
    };

    const handleMessageEvent = (event: MessageDetailsEvent): void => {
      setLastMessageEvent(event);
    };

    const handleUnbanningEvent = (event: UserUnbanEvent): void => {
      setLastUnbanningEvent(event);
    };

    socket.on(SOCKET_BANNING_DETAILS_EVENT, handleBanningEvent);
    socket.on(SOCKET_CHANNEL_DETAILS_EVENT, handleChannelEvent);
    socket.on(SOCKET_MESSAGE_DETAILS_EVENT, handleMessageEvent);
    socket.on(SOCKET_UNBANNING_DETAILS_EVENT, handleUnbanningEvent);

    socket.connect();

    return (): void => {
      socket.off(SOCKET_BANNING_DETAILS_EVENT, handleBanningEvent);
      socket.off(SOCKET_CHANNEL_DETAILS_EVENT, handleChannelEvent);
      socket.off(SOCKET_MESSAGE_DETAILS_EVENT, handleMessageEvent);
      socket.off(SOCKET_UNBANNING_DETAILS_EVENT, handleUnbanningEvent);
      socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ lastBanningEvent, lastUnbanningEvent, lastChannelEvent, lastMessageEvent }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextProps => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
