import { Box, Divider, FormControlLabel, Switch, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import ConversationsChat from './ConversationsChat/ConversationsChat';
import ConversationsButtons from './ConversationsButtons';
import { useChannel } from '../../../../application/context/ChannelContext';

const ConversationsBox: FC = () => {
  const { t } = useTranslation();
  const channelContext = useChannel();

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        height={'100%'}
        borderLeft='1px solid rgba(0, 0, 0, 0.12)'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flexShrink: '0' }}>
          <Box sx={{ p: `${theme.spacing(2)} ${theme.spacing(4)}` }}>
            <FormControlLabel
              control={
                <Switch
                  checked={channelContext.disableTranslation}
                  onChange={() => {
                    channelContext.switchDisableTranslation();
                  }}
                />
              }
              label={t('CONVERSATIONS.BOX.DISABLETRANSLATION')}
            />
          </Box>
          <Divider />
        </Box>
        <Box height={'100%'} sx={{ flexShrink: '1', overflowY: 'scroll' }}>
          <Box padding={theme.spacing(4)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography textTransform='uppercase'>{t('CONVERSATIONS.BOX.BUYER')}</Typography>
              <Typography textTransform='uppercase'>{t('CONVERSATIONS.BOX.SELLER')}</Typography>
            </Box>
            <Divider />
            <ConversationsChat />
          </Box>
        </Box>
        {(!channelContext.inReadonlyMode || channelContext.inRemoderateMode) && (
          <Box sx={{ flexShrink: '0' }}>
            <Divider />
            <ConversationsButtons />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ConversationsBox;
