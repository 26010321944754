import { createContext, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useToaster } from './ToasterContext';
import { useTranslation } from 'react-i18next';
import { Moderator } from '../types/Moderator';
import { adminGetModeratorList, adminPatchModeratorQueue } from '../services/AdminService';
import { ModeratorQueue } from '../types/dto/ModeratorDto';
import ApiError from '../services/error/ErrorService';

export interface AdminQueueMutationPayload {
  moderatorId: number;
  queue: ModeratorQueue[];
}

export type ModeratorQueueContextProps = {
  moderatorList: Moderator[];
  isPending: boolean;
  isQueryLoading: boolean;
  querryError: Error | null;
  editQueue: (moderatorId: number, valueToChange: ModeratorQueue) => void;
};

const ModeratorQueueContext = createContext<ModeratorQueueContextProps>({} as never);

export const ModeratorQueueProvider: FCWithChildren = ({ children }): React.JSX.Element => {
  const toaster = useToaster();
  const { t } = useTranslation();

  const [moderatorList, setModeratorList] = useState<Moderator[]>([]);

  const {
    data,
    isLoading: isQueryLoading,
    error: querryError,
  } = useQuery({
    queryKey: ['adminModerators'],
    queryFn: () => {
      return adminGetModeratorList();
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (p: AdminQueueMutationPayload) => adminPatchModeratorQueue(p.queue, p.moderatorId),
    onSuccess: (apiModerator: Moderator) => {
      const updatedModerator = moderatorList.map((moderator) =>
        moderator.id === apiModerator.id ? apiModerator : moderator,
      );

      setModeratorList(updatedModerator);
      toaster.success(t('ADMIN.QUEUE.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (a.status === 400 || a.status === 403) {
          toaster.error(t('ADMIN.QUEUE.TOASTER.NOTALLOWED'));
          return;
        } else if (a.status === 404) {
          toaster.error(t('ADMIN.QUEUE.TOASTER.NOTFOUND'));
          return;
        }
        toaster.error(t('ADMIN.QUEUE.TOASTER.DEFAULT'));
      } else {
        toaster.error(t('ADMIN.QUEUE.TOASTER.DEFAULT'));
      }
    },
  });

  useEffect(() => {
    setModeratorList(data ? data : []);
  }, [data]);

  const editQueue = (moderatorId: number, valueToChange: ModeratorQueue): void => {
    const index = moderatorList.findIndex((mod) => moderatorId === mod.id);
    if (index === -1) {
      toaster.error(t('ADMIN.QUEUE.TOASTER.NOTFOUND'));
      return;
    }

    const modQueue = [...moderatorList[index].queues];
    const indexToRemove = modQueue.findIndex((queueValue) => queueValue === valueToChange);

    if (indexToRemove === -1) {
      modQueue.push(valueToChange);
    } else {
      modQueue.splice(indexToRemove, 1);
    }

    mutate({ queue: modQueue, moderatorId });
  };

  return (
    <ModeratorQueueContext.Provider
      value={{
        editQueue,
        isPending,
        isQueryLoading,
        moderatorList,
        querryError,
      }}
    >
      {children}
    </ModeratorQueueContext.Provider>
  );
};

export const useModeratorQueue = (): ModeratorQueueContextProps => useContext(ModeratorQueueContext);
