export const SOCKET_CHANNEL_DETAILS_EVENT = 'CHANNEL_DETAILS_EVENT';

export interface ChannelDetailsEvent {
  id: number;
  createdAt: Date;
  updatedAt?: Date;
  sellerId: number;
  buyerId: number;
  createdBy?: ChannelCreatedBy;
  productId: number;
  productPicture: string;
}

enum ChannelCreatedBy {
  SELLER = 'SELLER',
  BUYER = 'BUYER',
}
