import { AuthModeratorDto, ModeratorDto, ModeratorQueue } from '../../application/types/dto/ModeratorDto';
import { ModeratorLanguage } from '../../application/types/ModeratorLanguage.enum';
import PapiClient from '../api/PapiClient';

export interface ModeratorLanguageBody {
  language: ModeratorLanguage;
}

export interface ModeratorQueueBody {
  queues: ModeratorQueue[];
}

export const patchModeratorLanguage = async (
  moderatorId: number,
  language: ModeratorLanguage,
): Promise<ModeratorDto> => {
  const body: ModeratorLanguageBody = {
    language,
  };

  return PapiClient.patch<ModeratorDto, ModeratorLanguageBody>(`/moderators/${moderatorId}`, body);
};

export const getModeratorsQueues = async (): Promise<AuthModeratorDto[]> => {
  return PapiClient.get<AuthModeratorDto[]>(`/admin/moderators`);
};

export const patchModeratorQueue = async (queues: ModeratorQueue[], moderatorId: number): Promise<AuthModeratorDto> => {
  const body: ModeratorQueueBody = {
    queues,
  };

  return PapiClient.patch<AuthModeratorDto, ModeratorQueueBody>(`/admin/moderators/${moderatorId}/queues`, body);
};
