import MainSection, { MainSectionProps } from '../../../ui/components/Layout/MainSection/MainSection';
import { FCWithChildren } from '../../../infrastructure/types/global';
import { useAuth } from '../../../application/context/AuthContext';
import { Typography } from '@mui/material';
import { isDefined } from '../../../utils/type-utils';
import ErrorComponent from '../helpers/error';
import { useTranslation } from 'react-i18next';
import SideBar from './SideBar/SideBar';

const drawerWidth = 64;

type ChatModLayoutProps = {
  mainSectionProps?: MainSectionProps;
};

const ChatModLayout: FCWithChildren<ChatModLayoutProps> = ({ mainSectionProps, children }) => {
  const { auth, isLoading } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      {isLoading ? (
        <Typography>Loading auth...</Typography>
      ) : (
        <>
          <SideBar />
          <MainSection {...mainSectionProps} open={true} drawerWidth={drawerWidth}>
            {isDefined(auth) ? (
              auth.isAuthorized && auth.isModerator ? (
                children
              ) : (
                <ErrorComponent errorCode={403} />
              )
            ) : (
              <Typography marginTop={20} textAlign='center'>
                {t('LOGIN.MESSAGE')}
              </Typography>
            )}
          </MainSection>
        </>
      )}
    </>
  );
};

export default ChatModLayout;
