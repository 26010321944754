import { Avatar, Box, Popover, SxProps, Typography } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from '../../../../theme';
import UserCardSubMenu from './UserCardSubMenu';
import { useModeratorLanguage } from '../../../../application/context/ModeratorContext';
import { useSideBarContext } from '../../../../application/context/SideBarContext';
import { isDefined } from '../../../../utils/type-utils';

type UserCardProps = {
  src?: string;
  sx?: SxProps;
  topLineText?: string;
  bottomLineText?: string;
  subMenu?: boolean;
};

const UserCard: FC<UserCardProps> = ({ src, sx, topLineText, bottomLineText, subMenu = false }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isSubMenuOpen, handleSubMenu } = useSideBarContext();
  const { flagToDisplay } = useModeratorLanguage();
  const anchorRef = useRef(null);

  useEffect(() => {
    if (isSubMenuOpen) {
      setTimeout((): void => {
        setAnchorEl(anchorRef.current);
      }, 200);
      return;
    }
    setAnchorEl(null);
  }, [isSubMenuOpen]);

  const id = isSubMenuOpen ? 'simple-popover' : undefined;

  return (
    <>
      <Box
        ref={anchorRef}
        id={subMenu ? 'user-card-anchor' : ''}
        sx={{ display: 'flex', ...sx }}
        onClick={() => {
          if (subMenu) {
            handleSubMenu();
          }
        }}
        aria-describedby={id}
      >
        <Avatar src={src} sx={{ marginRight: theme.spacing(1) }} />
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ lineHeight: '22px', fontWeight: 'bold', wordBreak: 'break-all' }}>{topLineText}</Typography>
          <Typography sx={{ lineHeight: '22px', wordBreak: 'break-all' }}>{bottomLineText}</Typography>
        </Box>
        {subMenu && <Avatar sx={{ width: 24, height: 24, marginRight: theme.spacing(1) }} src={flagToDisplay()} />}
      </Box>
      {subMenu && (
        <Popover
          id={id}
          open={isSubMenuOpen && isDefined(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => handleSubMenu()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <UserCardSubMenu handleClose={() => handleSubMenu()} />
        </Popover>
      )}
    </>
  );
};

export default UserCard;
