import { ModeratorLanguage } from './ModeratorLanguageDto.enum';

export interface ModeratorDto {
  language: ModeratorLanguage;
}

export enum ModeratorQueue {
  REGEX = 'regex',
  CIRCUMVENTION_LEVEL_1 = 'circumvention-level-1',
  CIRCUMVENTION_LEVEL_2 = 'circumvention-level-2',
  CIRCUMVENTION_LEVEL_3 = 'circumvention-level-3',
  SCAM_LEVEL_1 = 'scam-level-1',
  SCAM_LEVEL_2 = 'scam-level-2',
}

export interface AuthModeratorDto {
  id: number;
  email: string;
  name: string;
  language: ModeratorLanguage;
  queues: ModeratorQueue[];
}
