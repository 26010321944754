import { Box, Chip, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../../theme';
import CheckBox from '@mui/material/Checkbox';
import { useChannel } from '../../../../../application/context/ChannelContext';
import { Message } from '../../../../../application/types/Message';
import { isDefined } from '../../../../../utils/type-utils';
import { getStyles } from './ConversationsChatMessageStyleMap';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import { ModerationOutcome } from '../../../../../application/types/MessageModeration';
import { useTranslation } from 'react-i18next';

type ConversationsChatMessageProps = {
  isSeller: boolean;
  message: Message;
};

const ConversationsChatMessage: FC<ConversationsChatMessageProps> = ({ message, isSeller }) => {
  const context = useChannel();
  const { t } = useTranslation();
  const styles = getStyles(theme, isSeller, message);
  const { backgroundColor, borderColor, borderRadius, showWarning } = styles;

  const displayChip = (): boolean => {
    return !context.messageCanBeTranslated(message) && !context.disableTranslation;
  };

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: isSeller ? 'row-reverse' : 'row',
          gap: theme.spacing(1),
        }}
      >
        {!context.inReadonlyMode && (
          <CheckBox checked={message.isSelected} onChange={() => context.toggleMessageCheck(message.id)} />
        )}
        <Box
          sx={{
            backgroundColor,
            border: `1px solid ${borderColor}`,
            borderRadius,
            display: 'inline',
            maxWidth: '80%',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          }}
        >
          {context.messageToDisplay(message)}
          <Box
            sx={{
              marginTop: theme.spacing(1),
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              flexDirection: isSeller ? 'row' : 'row-reverse',
              alignItems: 'center',
            }}
          >
            {displayChip() && (
              <Box sx={{ flex: 1, textAlign: isSeller ? 'left' : 'right' }}>
                <Chip size='small' icon={<SubtitlesOffIcon />} label={t('CONVERSATIONS.BOX.TRANSLATION.CHIP')} />
              </Box>
            )}
            <Box sx={{ flex: 1, textAlign: isSeller ? 'right' : 'left' }}>
              <Typography variant='subtitle2'>
                {new Date(message.createdAt).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
              </Typography>
            </Box>
          </Box>
        </Box>
        {showWarning && (
          <SvgIcon
            sx={{
              fill: theme.palette.warning.main,
            }}
          >
            <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <path d='M9.83497 4.24951C10.7972 2.58285 13.2028 2.58284 14.1651 4.24951L21.7428 17.3745C22.7051 19.0412 21.5023 21.1245 19.5778 21.1245H4.42231C2.49781 21.1245 1.29499 19.0412 2.25724 17.3745L9.83497 4.24951ZM13.2991 4.74951C12.7217 3.74951 11.2783 3.74951 10.701 4.74951L3.12327 17.8745C2.54592 18.8745 3.26761 20.1245 4.42231 20.1245H19.5778C20.7325 20.1245 21.4541 18.8745 20.8768 17.8745L13.2991 4.74951Z' />
              <path d='M12.75 17.2495C12.75 17.6637 12.4142 17.9995 12 17.9995C11.5858 17.9995 11.25 17.6637 11.25 17.2495C11.25 16.8353 11.5858 16.4995 12 16.4995C12.4142 16.4995 12.75 16.8353 12.75 17.2495Z' />
              <path d='M11.35 14.9995V8.62451H12.65V14.9995H11.35Z' />
            </svg>
          </SvgIcon>
        )}
      </Box>

      <p
        style={{
          textAlign: isSeller ? 'right' : 'left',
          marginBottom: theme.spacing(2),
          paddingRight: theme.spacing(!context.inReadonlyMode ? 6.3 : 0),
          paddingLeft: theme.spacing(!context.inReadonlyMode ? 6.3 : 0),
          color: styles.borderColor,
          fontSize: theme.typography.caption.fontSize,
        }}
      >
        {isDefined(message.moderation.flaggedOutcome) &&
          t('CONVERSATIONS.CAPTION.MESSAGEFLAGGED', {
            flagType: t(`CONVERSATIONS.CLASSIFICATION.${message.moderation.flaggedOutcome}`),
          })}
        {!isDefined(message.moderation.flaggedOutcome) &&
          isDefined(message.moderation.moderationType) &&
          message.moderation.moderationType !== ModerationOutcome.SAFE_AUTOMATIC &&
          t('CONVERSATIONS.CAPTION.MESSAGEFLAGGED', {
            flagType: t(`CONVERSATIONS.CLASSIFICATION.${message.moderation.moderationType}`),
          })}
      </p>
    </>
  );
};

export default ConversationsChatMessage;
