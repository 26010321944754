import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Moderator } from '../../../../application/types/Moderator';
import { ModeratorQueue } from '../../../../application/types/dto/ModeratorDto';
import { useModeratorQueue } from '../../../../application/context/ModeratorQueueContext';

export type ModeratorTableProps = {
  moderatorList: Moderator[];
  isLoading: boolean;
  editQueue: Function;
};

const ModeratorQueueTable: FC = () => {
  const { t } = useTranslation();

  const { isPending, moderatorList, editQueue } = useModeratorQueue();

  const handleClick = (e: MouseEvent<HTMLButtonElement>, moderatorId: number, queueToEdit: ModeratorQueue): void => {
    e.preventDefault();
    editQueue(moderatorId, queueToEdit);
  };

  return (
    <Table stickyHeader sx={{ minWidth: 700 }} aria-label='customized table'>
      <TableHead>
        <TableRow>
          <TableCell>{t('ADMIN.QUEUE.TABLE.NAME')}</TableCell>
          <TableCell align='center'>{t('ADMIN.QUEUE.TABLE.SCAM2')}</TableCell>
          <TableCell align='center'>{t('ADMIN.QUEUE.TABLE.SCAM1')}</TableCell>
          <TableCell align='center'>{t('ADMIN.QUEUE.TABLE.CIR3')}</TableCell>
          <TableCell align='center'>{t('ADMIN.QUEUE.TABLE.CIR2')}</TableCell>
          <TableCell align='center'>{t('ADMIN.QUEUE.TABLE.CIR1')}</TableCell>
          <TableCell align='center'>{t('ADMIN.QUEUE.TABLE.REGEX')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {moderatorList.map((moderator) => {
          return (
            <TableRow key={moderator.id}>
              <TableCell>{moderator.name}</TableCell>
              <TableCell align='center'>
                <Checkbox
                  checked={moderator.queues.includes(ModeratorQueue.SCAM_LEVEL_2)}
                  disabled={isPending}
                  onClick={(e) => {
                    handleClick(e, moderator.id, ModeratorQueue.SCAM_LEVEL_2);
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <Checkbox
                  checked={moderator.queues.includes(ModeratorQueue.SCAM_LEVEL_1)}
                  disabled={isPending}
                  onClick={(e) => {
                    handleClick(e, moderator.id, ModeratorQueue.SCAM_LEVEL_1);
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <Checkbox
                  checked={moderator.queues.includes(ModeratorQueue.CIRCUMVENTION_LEVEL_3)}
                  disabled={isPending}
                  onClick={(e) => {
                    handleClick(e, moderator.id, ModeratorQueue.CIRCUMVENTION_LEVEL_3);
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <Checkbox
                  checked={moderator.queues.includes(ModeratorQueue.CIRCUMVENTION_LEVEL_2)}
                  disabled={isPending}
                  onClick={(e) => {
                    handleClick(e, moderator.id, ModeratorQueue.CIRCUMVENTION_LEVEL_2);
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <Checkbox
                  checked={moderator.queues.includes(ModeratorQueue.CIRCUMVENTION_LEVEL_1)}
                  disabled={isPending}
                  onClick={(e) => {
                    handleClick(e, moderator.id, ModeratorQueue.CIRCUMVENTION_LEVEL_1);
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <Checkbox
                  checked={moderator.queues.includes(ModeratorQueue.REGEX)}
                  disabled={isPending}
                  onClick={(e) => {
                    handleClick(e, moderator.id, ModeratorQueue.REGEX);
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ModeratorQueueTable;
