import { createContext, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { useMutation } from '@tanstack/react-query';
import { useToaster } from './ToasterContext';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';
import { patchModeratorLanguage } from '../../infrastructure/repositories/ModeratorRepository';
import { isDefined } from '../../utils/type-utils';
import { ModeratorLanguage } from '../types/ModeratorLanguage.enum';

export type ModeratorLanguageContextProps = {
  changePreferredLanguage: Function;
  moderatorPreferredLanguage: ModeratorLanguage;
  flagToDisplay: Function;
};

interface MutationObject {
  moderatorId: number;
  newLanguage: ModeratorLanguage;
}

const ModeratorLanguageContext = createContext<ModeratorLanguageContextProps>({} as never);

export const ModeratorLanguageProvider: FCWithChildren = ({ children }): React.JSX.Element => {
  const toaster = useToaster();
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [moderatorPreferredLanguage, setModeratorPreferredLanguage] = useState<ModeratorLanguage>(ModeratorLanguage.FR);

  useEffect(() => {
    if (!isDefined(auth) || !isDefined(auth.moderatorLanguage)) {
      return;
    }
    setModeratorPreferredLanguage(auth.moderatorLanguage);
  }, [auth]);

  const moderatorLanguageMutation = useMutation({
    mutationFn: (newValue: MutationObject) => patchModeratorLanguage(newValue.moderatorId, newValue.newLanguage),
    onSuccess: () => {
      toaster.success(t('SIDEBAR.LANGUAGESELECTION.SUCCESS'));
    },
    onError: () => {
      console.error('Error while patching moderator language');
      toaster.error(t('SIDEBAR.LANGUAGESELECTION.ERROR'));
    },
  });

  const flagToDisplay = (): string => {
    if (moderatorPreferredLanguage === ModeratorLanguage.FR) {
      return 'https://flagcdn.com/w40/fr.png';
    }
    return 'https://flagcdn.com/w40/gb.png';
  };

  const changePreferredLanguage = (newLanguage: ModeratorLanguage): void => {
    setModeratorPreferredLanguage(newLanguage);

    if (!isDefined(auth) || !isDefined(auth.moderatorId)) {
      toaster.defaultErrorsHandling(403, '');
      return;
    }
    moderatorLanguageMutation.mutate({ moderatorId: auth.moderatorId, newLanguage });
  };

  return (
    <ModeratorLanguageContext.Provider
      value={{
        moderatorPreferredLanguage,
        changePreferredLanguage,
        flagToDisplay,
      }}
    >
      {children}
    </ModeratorLanguageContext.Provider>
  );
};

export const useModeratorLanguage = (): ModeratorLanguageContextProps => useContext(ModeratorLanguageContext);
