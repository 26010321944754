import { ActionSource } from './UserBans';

export const SOCKET_BANNING_DETAILS_EVENT = 'user.banning.details';

export interface UserBanEvent {
  vcId: number;
  isBanned: boolean;
  banCreatedAt?: Date;
  banExpiresAt?: Date;
  banSource?: ActionSource;
}
