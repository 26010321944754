import { getModeratorsQueues, patchModeratorQueue } from '../../infrastructure/repositories/ModeratorRepository';
import { ModeratorQueue } from '../types/dto/ModeratorDto';
import { Moderator } from '../types/Moderator';

export const adminGetModeratorList = async (): Promise<Moderator[]> => {
  return getModeratorsQueues();
};

export const adminPatchModeratorQueue = async (queues: ModeratorQueue[], moderatorId: number): Promise<Moderator> => {
  return patchModeratorQueue(queues, moderatorId);
};
