import { ModerationOutcome } from './MessageModeration';

export const SOCKET_MESSAGE_DETAILS_EVENT = 'message.details';

export interface MessageDetailsEvent {
  id: number;
  moderation: ModerationOutcome;
  text: string;
  channelId: number;
  senderId: string;
  createdAt: string;
}
