import { User } from '../../core/entities/User';
import {
  getUserBanHistory,
  getUserByEmail,
  getUserByVcId,
  patchBanUser,
  patchUnbanUser,
} from '../../infrastructure/repositories/UserRepository';
import { stringToDate } from '../../utils/date-utils';
import { SearchKeywordType, typeToSearch } from '../../utils/search-utils';
import { isDefined } from '../../utils/type-utils';
import { UserDto } from '../types/dto/UserDto';
import { Member } from '../types/Member';
import { ActionSource, BanType, UserBanCount, UserBanHistory } from '../types/UserBans';

class UserService {
  public async getUser(user: string): Promise<User>;
  public async getUser(user: number): Promise<User>;
  public async getUser(user: Member): Promise<User>;

  public async getUser(user: string | Member | number): Promise<User> {
    if (!isDefined(user)) {
      throw new Error('Invalid value');
    }

    let response: Promise<UserDto>;

    try {
      if (typeof user === 'string') {
        const searchEmail = typeToSearch(user) === SearchKeywordType.EMAIL;
        response = searchEmail ? getUserByEmail(user) : getUserByVcId(Number(user));
      } else if (typeof user === 'number') {
        response = getUserByVcId(user);
      } else {
        response = getUserByVcId(user.vcId);
      }
    } catch {
      throw new Error('Invalid value');
    }

    return this.mapUserResponse(response);
  }

  public async banUser(userToBan?: User, duration?: number): Promise<User> {
    if (!isDefined(userToBan)) {
      throw new Error('Invalid value');
    }

    const response = patchBanUser(userToBan.id, duration);

    return this.mapUserResponse(response);
  }

  public async unbanUser(userToUnban?: User): Promise<User> {
    if (!isDefined(userToUnban)) {
      throw new Error('Invalid value');
    }

    const response = patchUnbanUser(userToUnban.id);

    return this.mapUserResponse(response);
  }

  public async mapUserResponse(response: Promise<UserDto>): Promise<User> {
    const responseData = await response;
    const banExpiryDate = isDefined(responseData.banExpiresAt) ? stringToDate(responseData.banExpiresAt) : undefined;

    const mappedResponse: User = await {
      ...responseData,
      banExpiryDate,
    };

    return mappedResponse;
  }

  public async getUserBanHistory(user: User | Member): Promise<UserBanHistory[]> {
    const response = await getUserBanHistory(user);
    return this.banHistoryDtoMap(response.history);
  }

  public getUserBanCount(UserBanHistory: UserBanHistory[]): UserBanCount {
    const temporaryBans =
      UserBanHistory.filter((ban) => ban.actionType === BanType.TEMPORARY_BAN).length -
      // Subtracting the automatic unbans from the temporary bans
      UserBanHistory.filter((ban) => ban.actionType === BanType.UNBAN && ban.source === ActionSource.CHATMOD_AUTOMATIC)
        .length -
      // Subtracting the review expired bans from the temporary bans
      UserBanHistory.filter((ban) => ban.actionType === BanType.REVIEW_EXPIRED_BAN).length;
    const permanentBans = UserBanHistory.filter((ban) => ban.actionType === BanType.PERMANENT_BAN).length;
    const unbans = UserBanHistory.filter((ban) => ban.actionType === BanType.UNBAN).length;

    return {
      temporaryBans,
      permanentBans,
      unbans,
    };
  }

  private banHistoryDtoMap(
    history: {
      actionType: BanType;
      moderator?: string;
      createdAt: string;
      expiresAt?: string;
      duration?: number;
      reason?: string;
      source: ActionSource;
      providerChannelId?: string;
    }[],
  ): UserBanHistory[] {
    return history.map((ban) => {
      const expiresAt = isDefined(ban.expiresAt) ? stringToDate(ban.expiresAt) : undefined;
      const createdAt = stringToDate(ban.createdAt);

      return {
        ...ban,
        createdAt,
        expiresAt,
      };
    });
  }
}

export const userService = new UserService();
