import { Box, Chip, Divider } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ConversationsChatMessage from './ConversationsChatMessage';
import ConversationsChatTimestamp from './ConversationsChatTimestamp';
import { theme } from '../../../../../theme';
import { useChannel } from '../../../../../application/context/ChannelContext';
import { isDefined } from '../../../../../utils/type-utils';
import { Message } from '../../../../../application/types/Message';

const ConversationsChat: FC = () => {
  const {
    channelId,
    messages,
    members,
    lastNewMessageIdSeen,
    resetLastMessageSeenId,
    error: channelError,
  } = useChannel();
  const [displayNewMessageButton, setDisplayNewMessageButton] = useState(false);
  const newMessageLineRef = useRef(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDefined(lastNewMessageIdSeen)) {
      setDisplayNewMessageButton(true);
    }
  }, [lastNewMessageIdSeen]);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry): void => {
        if (entry.isIntersecting && isDefined(lastNewMessageIdSeen)) {
          setDisplayNewMessageButton(false);
          setTimeout(() => {
            resetLastMessageSeenId();
          }, 5000);
        }
      });
    },
    [lastNewMessageIdSeen, resetLastMessageSeenId],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-50px',
      threshold: 0.1,
    });

    const saveMessageRefCurrent = newMessageLineRef.current;

    if (saveMessageRefCurrent) {
      observer.observe(saveMessageRefCurrent);
    }

    return (): void => {
      if (saveMessageRefCurrent) {
        observer.unobserve(saveMessageRefCurrent);
      }
    };
  }, [handleIntersection]);

  if (isDefined(channelError)) {
    return <div>Error: {channelError}</div>;
  } else if (!isDefined(channelId)) {
    return <div>Loading..</div>;
  }

  const goToNewMessages = (): void => {
    setDisplayNewMessageButton(false);

    if (isDefined(bottomRef.current)) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const shouldDisplayDate = (message: Message, index: number): boolean => {
    if (index === 0) {
      return true;
    }
    const messageDate = new Date(message.createdAt);
    const prevMessageDate = new Date(messages[index - 1].createdAt);
    if (
      prevMessageDate.getFullYear() !== messageDate.getFullYear() ||
      prevMessageDate.getMonth() !== messageDate.getMonth() ||
      prevMessageDate.getDate() !== messageDate.getDate()
    ) {
      return true;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(1),
      }}
    >
      {messages.map((message, index) => (
        <div key={`chatMessage-${message.id}`}>
          {lastNewMessageIdSeen === message.id && (
            <Divider
              sx={{
                '&::before, &::after': {
                  borderColor: 'rgba(102, 102, 102, 1)',
                },
              }}
              ref={newMessageLineRef}
            >
              New
            </Divider>
          )}
          {shouldDisplayDate(message, index) && (
            <ConversationsChatTimestamp timeToDisplay={new Date(message.createdAt)} />
          )}
          <ConversationsChatMessage
            isSeller={message.senderId === members.find((member) => member.role === 'SELLER')?.id}
            message={{
              ...message,
              isSelected: message.isSelected,
            }}
          />
        </div>
      ))}
      {displayNewMessageButton && (
        <Chip
          color='primary'
          size='medium'
          variant='filled'
          icon={<ArrowDownwardIcon />}
          label={'New Messages'}
          sx={{ position: 'sticky', bottom: theme.spacing(1), width: 138, left: `calc(50% - 69px)` }}
          onClick={() => goToNewMessages()}
        />
      )}
      <div ref={bottomRef}></div>
    </Box>
  );
};

export default ConversationsChat;
