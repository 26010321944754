import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { isDefined } from '../../utils/type-utils';
import { useParams, useNavigate } from 'react-router';
import { User } from '../../core/entities/User';
import { useTranslation } from 'react-i18next';
import { useToaster } from './ToasterContext';
import { userService } from '../services/UserService';
import ApiError from '../services/error/ErrorService';
import { UserDetailsTabs } from '../types/UserDetailsTabs';
import { ApplicationRoutes, RouteParts } from '../constants/navigation';
import { UserBanHistory } from '../types/UserBans';
import { UserDetailsMode } from '../../ui/components/Layout/UserDetails/UserDetailsMode';
import { useSocket } from './SocketContext';

interface UserDetailsContextProps {
  userDetails: User | null;
  userBanHistory: UserBanHistory[] | null;
  loading: boolean;
  error: string | null;
  unbanMutation: UseMutationResult<User, Error, User, unknown>;
  banMutation: UseMutationResult<User, Error, User, unknown>;
  confirmModalOpen: boolean;
  modalText: string;
  selectedTab: UserDetailsTabs;
  changeTab: (tab: UserDetailsTabs) => void;
  handleConfirmModal: () => void;
  closeConfirmModal: () => void;
  handleBanUnBanButtons: (e: React.MouseEvent<HTMLElement>, isBan: boolean, duration?: number) => void;
}

interface UserDetailsProviderProps {
  userVcIdProp?: string;
  modeProp: UserDetailsMode;
}

const UserDetailsContext = createContext<UserDetailsContextProps | undefined>(undefined);
const isUserDetailsTab = (tab: string | undefined): tab is UserDetailsTabs => {
  return Object.values(UserDetailsTabs).includes(tab as UserDetailsTabs);
};

export const UserDetailsProvider: FCWithChildren<UserDetailsProviderProps> = ({ children, userVcIdProp, modeProp }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [banDuration, setBanDuration] = useState<number | undefined>(undefined);
  const [userBanHistory, setUserBanHistory] = useState<UserBanHistory[] | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalText, setModalText] = useState(t('USER.BAN.CONFIRM'));
  const { userParam, tab: tabParam } = useParams<{ userParam: string; tab: string }>();
  const [userVcId] = useState<string | undefined>(isDefined(userVcIdProp) ? userVcIdProp : undefined);
  const [selectedTab, setSelectedTab] = useState<UserDetailsTabs>(
    isUserDetailsTab(tabParam) ? tabParam : UserDetailsTabs.PERSONAL_INFO,
  );
  const [mode] = useState(modeProp);
  const navigate = useNavigate();
  const userDetailsRef = useRef(userDetails);

  const { lastBanningEvent, lastUnbanningEvent } = useSocket();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (userParam) {
      queryClient.prefetchQuery({
        queryKey: ['user', userParam],
        queryFn: () => userService.getUser(userParam),
      });
    }
  }, [userParam, queryClient]);

  const {
    refetch: userRefetch,
    data: userQueryData,
    error: userQueryError,
    isLoading: userIsLoading,
  } = useQuery({
    queryKey: ['user', userVcId ?? userParam],
    queryFn: () => {
      if (isDefined(userVcId)) {
        return userService.getUser(userVcId);
      }
      if (isDefined(userParam)) {
        return userService.getUser(userParam);
      }
      throw new Error('No user id or user param provided');
    },
    retry: false,
    enabled: isDefined(userVcId) || isDefined(userParam),
  });

  useEffect(() => {
    if (!isDefined(userQueryData)) {
      return;
    }
    setUserDetails(userQueryData);
  }, [userQueryData]);

  useEffect(() => {
    if (!userIsLoading) {
      setLoading(false);
    }
  }, [userIsLoading]);

  useEffect(() => {
    if (userQueryError) {
      if (userQueryError instanceof ApiError && userQueryError.status === 404) {
        navigate(ApplicationRoutes.NO_RESULTS);
      } else {
        const error = 'Failed to fetch user';
        console.error(error);
        setError(error);
      }
    }
  }, [userQueryError, navigate]);

  const changeTab = (tab: UserDetailsTabs): void => {
    setSelectedTab(tab);
    if (mode === UserDetailsMode.STANDLONE) {
      window.history.replaceState(null, '', `/${RouteParts.USERS}/${userVcId ?? userParam}/${tab}`);
    }
  };

  const { data: fetchedUserBanHistory, isLoading: isUserBanHistoryLoading } = useQuery({
    queryKey: ['banCount', userDetails?.id],
    queryFn: () => {
      if (!isDefined(userDetails)) {
        return;
      }
      return userService.getUserBanHistory(userDetails);
    },
    staleTime: Infinity,
    enabled: isDefined(userDetails),
  });

  useEffect(() => {
    if (!isDefined(fetchedUserBanHistory)) {
      return;
    }
    setUserBanHistory(fetchedUserBanHistory);
  }, [fetchedUserBanHistory]);

  useEffect(() => {
    userDetailsRef.current = userDetails;
  }, [userDetails]);

  useEffect(() => {
    if (isDefined(lastBanningEvent) && isDefined(userDetailsRef.current)) {
      if (lastBanningEvent.vcId === userDetailsRef.current.vcId) {
        queryClient.invalidateQueries({ queryKey: ['banCount', userDetailsRef.current.id] });
        userRefetch();
      }
    }
  }, [lastBanningEvent, userRefetch, queryClient]);

  useEffect(() => {
    if (isDefined(lastUnbanningEvent) && isDefined(userDetailsRef.current)) {
      if (lastUnbanningEvent.vcId === userDetailsRef.current.vcId) {
        queryClient.invalidateQueries({ queryKey: ['banCount', userDetailsRef.current.id] });
        userRefetch();
      }
    }
  }, [lastUnbanningEvent, userRefetch, queryClient]);

  const banMutation = useMutation({
    mutationFn: (banTarget: User) => userService.banUser(banTarget, banDuration),
    onSuccess: (a) => {
      if (!isUserBanHistoryLoading) {
        queryClient.invalidateQueries({ queryKey: ['banCount', a.id] });
      }
      setUserDetails(a);
      toaster.success(t('USER.BAN.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (toaster.defaultErrorsHandling(a.status, 'User')) {
          return;
        } else if (a.status === 409) {
          toaster.error(t('USER.BAN.TOASTER.ERROR.BANINPROGRESS'));
          return;
        }
        toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'));
      } else {
        toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'));
      }
    },
  });

  const unbanMutation = useMutation({
    mutationFn: (banTarget: User) => userService.unbanUser(banTarget),
    onSuccess: (a) => {
      if (!isUserBanHistoryLoading) {
        queryClient.invalidateQueries({ queryKey: ['banCount', a.id] });
      }
      setUserDetails(a);
      toaster.success(t('USER.UNBAN.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (toaster.defaultErrorsHandling(a.status, 'User')) {
          return;
        } else if (a.status === 409) {
          toaster.error(t('USER.UNBAN.TOASTER.ERROR.BANINPROGRESS'));
          return;
        }
        toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
      } else {
        toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
      }
    },
  });

  const handleConfirmModal = (): void => {
    setConfirmModalOpen(false);
    if (!isDefined(userDetails)) {
      return;
    }

    if (!userDetails?.isBanned) {
      banMutation.mutate(userDetails);
    } else {
      unbanMutation.mutate(userDetails);
    }
  };

  const closeConfirmModal = (): void => {
    setConfirmModalOpen(false);
  };

  const handleBanUnBanButtons = (e: React.MouseEvent<HTMLElement>, isBan: boolean, duration?: number): void => {
    e.preventDefault();
    const modalText = isBan ? t('USER.BAN.CONFIRM') : t('USER.UNBAN.CONFIRM');
    setBanDuration(duration);
    setModalText(modalText);
    setConfirmModalOpen(true);
  };

  useEffect(() => {
    if (isDefined(userVcId) || isDefined(userParam)) {
      userRefetch();
    }
  }, [userVcId, userParam, userRefetch]);

  return (
    <UserDetailsContext.Provider
      value={{
        userDetails,
        userBanHistory,
        loading,
        error,
        unbanMutation,
        banMutation,
        confirmModalOpen,
        modalText,
        selectedTab,
        changeTab,
        handleConfirmModal,
        closeConfirmModal,
        handleBanUnBanButtons,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export const useUserDetails = (): UserDetailsContextProps => {
  const context = useContext(UserDetailsContext);
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }
  return context;
};
