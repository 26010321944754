import { Box } from '@mui/material';
import { FC } from 'react';
import TopBar from '../TopBar/TopBar';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../theme';
import ModeratorQueueTable from './ModeratorQueueTable';
import { isDefined } from '../../../../utils/type-utils';
import { useModeratorQueue } from '../../../../application/context/ModeratorQueueContext';

const ModeratorQueueComponent: FC = () => {
  const { t } = useTranslation();
  const { isQueryLoading, querryError } = useModeratorQueue();

  return (
    <Box>
      <TopBar message={t('ADMIN.HEADER.TITLE')}></TopBar>
      {isQueryLoading ? (
        <Box>Loading datas...</Box>
      ) : (
        <Box
          sx={{
            padding: theme.spacing(2),
            height: '100%',
          }}
        >
          {isDefined(querryError) ? <Box>{t('ADMIN.QUEUE.NOTALLOWED')}</Box> : <ModeratorQueueTable />}
        </Box>
      )}
    </Box>
  );
};

export default ModeratorQueueComponent;
